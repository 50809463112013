function DomainResponseDto(obj){
    return {
        identityProviderId : ((obj) ? obj.identityProviderId : null),
        identityProviderCertificate : ((obj) ? obj.identityProviderCertificate : null),
        identityProviderSSOBaseURL : ((obj) ? obj.identityProviderSSOBaseURL : null),
        identityProviderSSOServiceURL : ((obj) ? obj.identityProviderSSOServiceURL : null),
        identityProviderSSORedirectURL : ((obj) ? obj.identityProviderSSORedirectURL : null),
        entityId : ((obj) ? obj.entityId : null),
        acsUrl : ((obj) ? obj.acsUrl : null),
        panwCert : ((obj) ? obj.panwCert : null),
        supportAccountId :  ((obj) ? obj.supportAccountId : null),
        isActive : ((obj) ? obj.isActive : null),
        metadata : ((obj) ? obj.metadata : null),
        idPConfigurationId: ((obj) ? obj.idPConfigurationId : null),
        migratedToOkta: ((obj) ? obj.migratedToOkta : null),
        activatedInOkta: ((obj) ? obj.activatedInOkta : null),
        enableForm: ((obj) ? obj.enableForm : null),
        displayEnableSSO: ((obj) ? obj.displayEnableSSO : null)
    }
}

module.exports = DomainResponseDto;
