import React from 'react';
import { Link } from 'react-router-dom';
import './AccountsHeader.css';

const AccountsHeader = (props) => {
  const { pageMetaData, pageTitle } = props;

    return (
      <div>
        <div className="main-top-bar">
          <div className="nav-bar">
          <ul>
            <li className="logoHead"><span>{pageTitle}</span></li>
            <li className="profile-bar">
              <div>{pageMetaData.userFirstName} {pageMetaData.userLastName}<span><img alt="&#8964;" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M8.5 9.8l3.65-3.65a.5.5 0 0 1 .7.7l-4 4a.5.5 0 0 1-.7 0l-4-4a.5.5 0 0 1 .7-.7L8.5 9.8z'/%3E%3C/svg%3E"></img></span></div>
              <div className="ProfileAccount">{pageMetaData.userCompany}</div>
              <div className="top-drop-down">
                <ul>
                  <li><Link to="/logout">Sign Out</Link></li>
                </ul>
              </div>
            </li>
          </ul>
          </div>
        </div>
      </div>
    );
}

export default AccountsHeader;
