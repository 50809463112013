/* eslint-disable no-unused-expressions */

const getJson = (obj) => {
  const objKeys = obj;
  console.log(objKeys)
}

const testUrl = (url) => {
  try {
      new URL(url);

      return true;
  } catch (_) {
      return false;
  }
}

module.exports = {
  getJson,
  testUrl
}