import _defineProperty from "@babel/runtime/helpers/defineProperty";
import Axios from 'axios';
// eslint-disable-next-line no-unused-expressions
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }


var AUTHORIZATION_HEADER_NAME = 'Authorization';
var HttpClient = {};

function getHeaders() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

  var headers = _objectSpread({}, options);

  if (options) {
    if (options.authToken) {
      headers[AUTHORIZATION_HEADER_NAME] = "Bearer ".concat(options.authToken);
      delete headers.authToken;
    }

    delete headers.withCredentials;
  }

  return headers;
}

HttpClient.get = function (endpoint, headers, options) {
  return Axios.get(endpoint, _objectSpread({
    headers: getHeaders(headers),
    withCredentials: headers && typeof headers.withCredentials !== 'undefined' ? headers.withCredentials : true
  }, options));
};

HttpClient.post = function (endpoint, data, headers, options) {
  return Axios.post(endpoint, data, _objectSpread({
    headers: getHeaders(headers),
    withCredentials: headers && typeof headers.withCredentials !== 'undefined' ? headers.withCredentials : true
  }, options));
};

HttpClient.put = function (endpoint, data, headers, options) {
  return Axios.put(endpoint, data, _objectSpread({
    headers: getHeaders(headers),
    withCredentials: headers && typeof headers.withCredentials !== 'undefined' ? headers.withCredentials : true
  }, options));
};

HttpClient.patch = function (endpoint, data, headers, options) {
  return Axios.patch(endpoint, data, _objectSpread({
    headers: getHeaders(headers),
    withCredentials: headers && typeof headers.withCredentials !== 'undefined' ? headers.withCredentials : true
  }, options));
};

HttpClient.delete = function (endpoint, headers, options) {
  return Axios.delete(endpoint, _objectSpread({
    headers: getHeaders(headers),
    withCredentials: headers && typeof headers.withCredentials !== 'undefined' ? headers.withCredentials : true
  }, options));
};

HttpClient.request = function (config) {
  return Axios.request(config);
};
/**
 * use to fetch 1 or more data sources, return the array of responses.
 *
 * @param {(Object|Object[])} source - The sources of the data
 * @param {string} source.uri - The URI from where to fecth the data.
 * @param {string} source.propName - The name of the prop where the data will be passed to the element.
 * @param {string} [source.resData=null] - The source on the response for the data.
 * @param {function} [source.parser=null] - An optional parser for the data in the response.
 * @param {Object} [source.options=null] - Options for the request if any. This overrides the global options.
 * @param {string} [source.options.authToken=null] - Authorization token to be included in header.
 * @param {Object} [options=null] - Options applicable to all of the requests if any.
 * @returns {Promise} - returns a promise with the requested data
 */


HttpClient.fetch = function (source) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var data = {};
  var dataSources = Array.isArray(source) ? source : [source];
  var requests = [];
  dataSources.forEach(function (req) {
    requests.push(HttpClient.get(req.uri, req.options || options));
  });
  return new Promise(function (resolve, reject) {
    Promise.all(requests).then(function (responses) {
      for (var i = 0; i < responses.length; i++) {
        var actualData = dataSources[i].resData ? responses[i].data[source[i].resData] : responses[i].data;
        data[dataSources[i].propName] = dataSources[i].parser ? dataSources[i].parser(actualData) : actualData;
      }

      resolve(data);
    }).catch(function (error) {
      reject(error);
    });
  });
};

export default HttpClient;