import React, { useState } from 'react';
import { Spin } from 'antd';
import store from 'store';
import AccountsHeader from 'src/components/AccountsHeader/AccountsHeader'
import SSOConfiguration from 'src/components/SSOConfiguration/SSOConfiguration'
import 'src/components/SSO/SSO.css';

const SSO = (props) => {
  const pageTitle = "Accounts Configuration";
  const metaData = {
    userFirstName: store.get('userFirstName') || "",
    userLastName: store.get('userLastName') || "",
    userCompany: store.get('userCompany') || ""
  }
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  return (
    <Spin spinning={spinnerVisible} fullscreen="true">
      <div>
        <AccountsHeader pageTitle={pageTitle} pageMetaData={metaData} />
        <div className="form-container">
          <SSOConfiguration 
            pageMetaData={metaData}
            setSpinnerVisible={setSpinnerVisible}
          />
        </div>
      </div>
    </Spin>
  );
}

export default SSO;
