import React from 'react';
import {Modal } from 'antd';


const ModalDialog = (props) => {
  const { onOkFunc, onCancelFunc, dialogObj, okText, cancelText } = props;
  Modal.confirm({
    title: dialogObj?.title,
    content: dialogObj?.content,
    destroyOnClose: true,
    okText: okText || 'OK',
    cancelText: cancelText || 'Cancel',
    onOk: async function(values) {
      onOkFunc();
     },
     onCancel: onCancelFunc,
    footer: (_, { OkBtn, CancelBtn }) => (
      <>
        <CancelBtn />
        <OkBtn />
      </>
    ),
  });
};


export default ModalDialog;