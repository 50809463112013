import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import NotFound from './common/constants/NotFound'
import SSO from 'src/components/SSO/SSO';
import handleLogout from 'src/login/Logout';
import getConfig from "src/utils/ConfigHelper";
import HttpClient from "src/utils/HttpClient";
import store from "store";
import 'src/assets/style/main.css';

const AUTH_API =  getConfig('BASE_URL') + getConfig('AUTH_API');
const LOGIN_URL =  getConfig('LOGIN_URL');

const App = (props) => {
    store.set('loggedIn', false);
    const [authenticationError, setAuthenticationError] = useState('');

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        window.history.scrollRestoration = 'manual'
      }, []);
    useEffect(() => {
        console.log("AUTHENTICATING: " + AUTH_API)
        HttpClient.post(AUTH_API)
            .then(response => {
                if(response?.data?.mail) {
                    store.set('userEmail', response?.data?.mail);
                    store.set('domainName', response?.data?.mail?.split("@")[1]);
                    store.set('userFirstName', response?.data?.firstName);
                    store.set('userLastName', response?.data?.lastName);
                    store.set('userCompany', response?.data?.company);
                    store.set('loggedIn', true);
                    setIsLoggedIn(true);
                    console.log("is looged in: " + store.get('loggedIn'));
                } else {
                    window.location.href = LOGIN_URL + window.location.href ;
                    // window.location.href = LOGIN_URL;
                }
            })
            .catch((error) => {
                console.log("Authentication Error: " + error.message)
                var errormessage = ((error?.response?.status == "403" || error?.response?.status == "401") ) ? getConfig("UNAUTHORIZED_ERROR_MESSAGE") : getConfig('ERROR_MESSAGE');
                setAuthenticationError(errormessage);
        })
    }, [])

    const browserRouter = (isLoggedIn) => (isLoggedIn ?
        (<BrowserRouter>
            <Switch>
                <Route path="/sso/" exact component={NotFound} />
                <Route path='/sso/:supportAccountId?' component={SSO} />
                <Route path='/logout' component={() => handleLogout()}/> 
                <Route path="*" component={NotFound} />
            </Switch>
        </BrowserRouter>)
   : <div className='errorFont'>{authenticationError}</div>);

    return (browserRouter(isLoggedIn));
}

export default App;

