import config from 'src/config.js'

export default function getConfig(name) {
    let retValue = null;

    if (name && name.length > 0) {
        retValue = config[name] || null;
    }
    return retValue;
}
