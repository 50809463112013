const local = {
  BASE_URL: "http://localhost:9080",
  LOGIN_URL: "https://ssopreview.paloaltonetworks.com/app/panw-ciam_3rdpartyidplocal_1/exk3wxhvq5OoULTAl1d7/sso/saml?RelayState=",
  LOGOUT_URL: "https://ssopreview.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support.qa.app.paloaltonetworks.com/",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  ENV: "local",
  IS_BETA: true,
};

const dev = {
  BASE_URL: "https://api.accounts-dev.paloaltonetworks.com",
  LOGIN_URL: "https://ssopreview.paloaltonetworks.com/app/panw-ciam_3rdpartyidpdev_1/exk3wxfnb6usspEZr1d7/sso/saml?RelayState=",
  LOGOUT_URL: "https://ssopreview.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support.qa.app.paloaltonetworks.com/",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  ENV: "dev",
  IS_BETA: true,

};

const sit = {
  BASE_URL: "https://api.accounts-sit.paloaltonetworks.com",
  LOGIN_URL: "https://ssopreview.paloaltonetworks.com/app/panw-ciam_3rdpartyidpsit_1/exk3wxhajknE06T8E1d7/sso/saml?RelayState=",
  LOGOUT_URL: "https://ssopreview.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support.qa.app.paloaltonetworks.com/",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  ENV: "sit",
};

const sit_herc = {
  BASE_URL: "https://dev.api.paloaltonetworks.com/accounts-bff-sit-herc",
  LOGIN_URL: "https://ssopreview.paloaltonetworks.com/app/panw-ciam_3rdpartyidpsitherc_1/exk6xtp76hAx1ZDeg1d7/sso/saml?RelayState=", // need new Ping endpoint
  LOGOUT_URL: "https://ssopreview.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support.hercsit.app.paloaltonetworks.com",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  ENV: "sit_herc",
};

const qa = {
  BASE_URL: "https://api.accounts-qa.paloaltonetworks.com",
  LOGIN_URL: "https://ssopreview.paloaltonetworks.com/app/panw-ciam_3rdpartyidpqanew_1/exk3wxgfvndQUPPNe1d7/sso/saml?RelayState=",
  LOGOUT_URL: "https://ssopreview.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support.qa.app.paloaltonetworks.com/",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  ENV: "qa",
};

const stage = {
  BASE_URL: "https://api.accounts-stage.paloaltonetworks.com",
  LOGIN_URL: "https://identitytest.paloaltonetworks.com/idp/startSSO.ping?PartnerSpId=3rdPartyIDP_STAGE&TargetResource=",
  LOGOUT_URL: "https://logintest.paloaltonetworks.com/logout",
  CSP_URL: "https://support.qa.app.paloaltonetworks.com/",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  ENV: "stage",
};

const stage_herc = {
  BASE_URL: "https://stg.api.paloaltonetworks.com/accounts-bff-stage-herc",
  LOGIN_URL: "https://ssopreview.paloaltonetworks.com/app/panw-ciam_3rdpartyidpstageherc_1/exk6z3v8mry1pZ9VY1d7/sso/saml?RelayState=", // need new Ping endpoint
  LOGOUT_URL: "https://ssopreview.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support.hercstg.app.paloaltonetworks.com",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  IS_BETA: true,
  ENV: "stage_herc",
};

const prod = {
  BASE_URL: "https://accounts.api.paloaltonetworks.com",
  LOGIN_URL: "https://sso.paloaltonetworks.com/app/panw-ciam_3rdpartyidp_1/exk2pvztgKVNYUqLQ0j6/sso/saml?RelayState=",
  LOGOUT_URL: "https://sso.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support.paloaltonetworks.com",
  APP_PORTAL_URL: "https://apps.paloaltonetworks.com",
  IS_BETA: true,
  ENV: "prod",
};

const stage_fed = {
  BASE_URL: "https://api.accounts-qa.paloaltonetworks.com",
  LOGIN_URL: "https://logintest-fed.paloaltonetworks.com/app/panw-ciam_3rdpartyidpqanew_1/exk3wxgfvndQUPPNe1d7/sso/saml?RelayState=",
  LOGOUT_URL: "https://logintest-fed.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support-fed-qa.paloaltonetworks.com/",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  ENV: "stage_fed",
};

const qa_fed = {
  BASE_URL: "https://api.accounts-qa.paloaltonetworks.com",
  LOGIN_URL: "https://logintest-fed.paloaltonetworks.com/app/panw-ciam_3rdpartyidpqanew_1/exk3wxgfvndQUPPNe1d7/sso/saml?RelayState=",
  LOGOUT_URL: "https://logintest-fed.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support-fed-qa.paloaltonetworks.com/",
  APP_PORTAL_URL: "https://apps-qa2-uat.app-portal-qa.us.paloaltonetworks.com/apps",
  ENV: "qa_fed",
};

const prod_fed = {
  BASE_URL: "https://accounts.api.paloaltonetworks.com",
  LOGIN_URL: "https://login-fed.paloaltonetworks.com/app/panw-ciam_3rdpartyidp_1/exk2pvztgKVNYUqLQ0j6/sso/saml?RelayState=",
  LOGOUT_URL: "https://login-fed.paloaltonetworks.com/login/signout",
  CSP_URL: "https://support-fed.paloaltonetworks.com",
  APP_PORTAL_URL: "https://apps.paloaltonetworks.com",
  IS_BETA: true,
  ENV: "prod_fed",
};

var config;

switch (process.env.REACT_APP_STAGE) {
  case 'prod':
    config = prod;
    break;
  case 'stage':
    config = stage;
    break;
  case 'qa':
    config = qa;
    break;
  case 'stage_herc':
    config = stage_herc;
    break;
  case 'sit':
    config = sit;
    break;
  case 'sit_herc':
    config = sit_herc;
    break;
  case 'dev':
    config = dev;
    break;
  case 'qa_fed':
    config = qa_fed;
    break;
  case 'stage_fed':
    config = stage_fed;
    break;
  case 'prod_fed':
    config = prod_fed;
    break;
  default:
    config = local;
};

export default {
  // Add common config values here
  AUTH_API: "/authenticate",
  SSO_CONFIGURATION_GET_API: "/v1/ssoConfiguration/load",
  SSO_CONFIGURATION_POST_API: "/v1/ssoConfiguration/submit",
  SSO_CONFIGURATION_ENABLE_API: "/v1/ssoConfiguration/enable",
  SSO_CONFIGURATION_DOMAIN_METADATA_API: "/v1/ssoConfiguration/domains/{domain}/metadata/{idPId}",
  SSO_CONFIGURATION_DOMAIN_ACTIVATE_API: "/v1/ssoConfiguration/domains/{domain}/activate",
  ROLES_PATH: "/roles",
  ADD_USERS_PATH: "/SupportAccount/AccountUsers/",
  CONFIG: "?config=1",
  ERROR_MESSAGE: "An error occurred while processing your request, please contact: ssoadmins@paloaltonetworks.com",
  UNAUTHORIZED_ERROR_MESSAGE: "You are not authorized to perform this operation, please contact: ssoadmins@paloaltonetworks.com",
  DOCUMENT_LINK: "https://knowledgebase.paloaltonetworks.com/KCSArticleDetail?id=kA14u000000sZ8wCAE",
  SETUP_DOCUMENT_LINK: "https://knowledgebase.paloaltonetworks.com/KCSArticleDetail?id=kA14u000000sZ8mCAE",
  SESSION_EXPIRED_MESSAGE: "Session expired please refresh and try again.",
  BAD_REQUEST_ERROR_MESSAGE: "Invalid request, please enter valid input",
  ...config
};
