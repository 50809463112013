import React from "react";
import { Tooltip } from "antd";
import { FaQuestionCircle, FaDownload} from 'react-icons/fa';
import store from 'store';

const panwProviderFormItemsConfig = (domainResponse, copyToClipboard, downLoadFile, downLoadMetaData) => {
  return {
    isCollapsible: false,
    heading: 'Palo Alto Networks Service Provider Information',
    items: [
        {
          id: 'msgfieldPANWInfo',
          name: 'msgfieldPANWInfo',
          type: 'element',
          bordered: false,
          element: <span id="msgfieldPANWInfo">Your identity provider may require you to enter information about Palo Alto Networks.</span>,
        },
        {
          id:   'entityId',
          type: 'element',
          label: <Tooltip title='The intended audience of the SAML assertion. This is the same as Entity ID.'>
          <span id="msgfieldPANWInfo">ENTITY ID</span> <FaQuestionCircle /></Tooltip>,
          element: <span><div id='entityIdValue'>{domainResponse?.entityId}</div><div className="small-button"><a href="#" onClick={() => (copyToClipboard('entityIdValue'))}>Copy</a></div></span>,
        },
        {
          id: 'acsUrl',
          type: 'element',
          label: <Tooltip title='The location where the SAML assertion is sent with a POST operation.'>
                  <span id="msgfieldPANWInfo">ACS URL</span> <FaQuestionCircle /></Tooltip>,
          element: <span><div id='acsUrlValue'>{domainResponse?.acsUrl}</div><div className="small-button"><a href="#" onClick={() => (copyToClipboard('acsUrlValue'))}>Copy</a></div></span>,
        },
        {
          id: 'panwCert',
          type: 'element',
          label: <Tooltip title='Public key certificate you can use to validate the SAML sign-in request (SAML AuthRequest)'>
                  <span id="msgfieldPANWInfo">CERTIFICATE</span> <FaQuestionCircle /></Tooltip>,
          element: <span><div className="divWrapWithScroll"  id='panwCertValue' >{domainResponse?.panwCert} </div>
          <div className="marginTop"><span className="small-button"><a  href="#" onClick={() => (copyToClipboard('panwCertValue'))}>Copy</a></span>
          &nbsp;&nbsp;&nbsp;
          <a href="#" onClick={() => (downLoadFile(domainResponse?.panwCert,'.pem','certificate.pem'))}>
              Download Certificate <FaDownload /></a></div>
          </span>
        },
        {
          id: 'metadata',
          type: 'element',
          label: <Tooltip title='The Service provider metadata that contains keys, URLs, and other information necessary for interaction with SAML-enabled identity provider'>
                <span id="msgfieldPANWInfo">METADATA</span> <FaQuestionCircle /></Tooltip>,
          element: <span><div className="marginTop">
            <a href="#" onClick={() => (downLoadMetaData(store.get('domainName'),domainResponse?.idPConfigurationId))}>
            Download Metadata <FaDownload /></a>
            </div><br/></span>
        }]
      }
};

export default panwProviderFormItemsConfig;
