import React, { useEffect, useState} from 'react';
import store from 'store';
import { message } from 'antd';
import getConfig from 'src/utils/ConfigHelper';
import domainResponseDto from 'src/models/DomainResponseDto';
import HttpClient from 'src/utils/HttpClient';
import IdentityProviderForm from 'src/components/IdentityProviderForm/IdentityProviderForm.jsx';
import './SSOConfiguration.css';

const SSO_CONFIGURATION_GET_API = getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_GET_API');

const SSOConfiguration = (props) => {
  const { pageMetaData, setSpinnerVisible } = props
  const [domainResponse, setDomainResponse] = useState(domainResponseDto());
  const [enableSSOChecked, setEnableSSOChecked] = useState(domainResponseDto()?.isActive);
  const [activatedInOktaChecked, setActivatedInOktaChecked] = useState(domainResponseDto()?.activatedInOkta);
  const [panwInfoPanelVisible, setPanwInfoPanelVisible] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [afterSubmitMessage, setAfterSubmitMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {

    const options = {authToken: `abcd`}
    setSpinnerVisible(true);

    // fetching Doamin Configuration if exsists
    HttpClient.get(SSO_CONFIGURATION_GET_API, options).then(response => {
      setSpinnerVisible(false);
      if(response.status === 200){
        var responseDto = domainResponseDto(response?.data?.data); 
        setDomainResponse(responseDto); //orig
        // setPanwInfoPanelVisible(responseDto?.entityId !== null);// orig
        setPanwInfoPanelVisible(!!responseDto?.entityId);
        setEnableSSOChecked(responseDto?.isActive);
        setActivatedInOktaChecked(responseDto?.activatedInOkta);
     } else {
        setSubmissionStatus('error');
        setAfterSubmitMessage(getConfig('ERROR_MESSAGE'));
        setShowMessage(true)
        setSpinnerVisible(false);
     }
    }).catch(error => {
      console.log("SSO Configuration Fetch Error: " + JSON.stringify(error.message))
      setSpinnerVisible(false);
      setSubmissionStatus('error');
      setAfterSubmitMessage(getConfig('ERROR_MESSAGE'));
      setShowMessage(true)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (showMessage === true) {
      message[submissionStatus]({
        content: afterSubmitMessage,
        icon: null,
        duration: 10,
        maxCount: 1,
        style: { marginTop: '4vh' },
        onClick: () => message.destroy()
      }) 
      setShowMessage(false);
    }
  }, [showMessage, afterSubmitMessage, submissionStatus])


    return (
      <div className="main-form">
        <br/>
        <h2>Single Sign-On Configuration: {store.get('domainName')}</h2>
          <IdentityProviderForm 
            pageMetaData={pageMetaData}
            domainResponse={domainResponse}
            setDomainResponse={setDomainResponse}
            panwInfoPanelVisible={panwInfoPanelVisible} 
            setPanwInfoPanelVisible={setPanwInfoPanelVisible}
            enableSSOChecked={enableSSOChecked}
            setEnableSSOChecked={setEnableSSOChecked}
            activatedInOktaChecked={activatedInOktaChecked}
            setActivatedInOktaChecked={setActivatedInOktaChecked}
            setSubmissionStatus={setSubmissionStatus}
            setAfterSubmitMessage={setAfterSubmitMessage}
            setSpinnerVisible={setSpinnerVisible}
            setShowMessage={setShowMessage}
          />
      </div>
    );
}


export default SSOConfiguration;
