import React from 'react';
import { Form, Input, Button, Switch, 
  Upload, 
  message, Alert, Col, Row  } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getJson } from './utilities';

const GetInput = (inputProps) => {
  return (
    <Form.Item
      name={inputProps?.name}
      label={inputProps?.label}
      key={Math.random()}
      rules={inputProps?.rules}
    >
    <Input disabled={inputProps?.disabled} />
    </Form.Item>
  )
}

const GetTextArea = (textAreaProps) => {

  return (
    <Form.Item
        name={textAreaProps?.name}
        label={textAreaProps?.label}
        initialValue={textAreaProps?.initialValue}
        rules={textAreaProps?.rules}
        key={Math.random()}
        
      >
        <Input.TextArea disabled={textAreaProps?.disabled}  width={500} rows={6} />
      </Form.Item>
  )
  }
const GetSwitch = (switchProps, switchState) =>  {
  return(
      <Form.Item
        name={switchProps?.name}
        label={switchProps?.label}
        key={Math.random()}
        rules={switchProps?.rules}

      >
        <span className={'switch-padding'}>
          <Switch onClick={(val)=> switchProps?.onChange(val)} checked={switchProps?.defaultValue}/>
        </span>
          
      </Form.Item>
)}

const GetUploadFile = (uploadFileProps, form, certList, setCertList) => {
  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);

    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const getFile = (e) => {
    //remove file from form upload.
    console.log("GET FIEL: " + JSON.stringify(e))
    if (Array.isArray(e)) {
      console.log("IT IS ARRAY")
      return e;
    }
    console.log("NOT AN ARRAY")
   return e && e.fileList;
  //  return e;
  };
  

  return (
    <Form.Item
      name={uploadFileProps?.name}
      valuePropName='file'
      label={uploadFileProps?.label}
      key={Math.random()}
      rules={uploadFileProps?.rules}
      getValueFromEvent={getFile}
    >
      <Upload 
        onChange={onChange}
        defaultFileList={form.getFieldValue("identityProviderCertificate")}
        accept=".txt, .csv, .pem, .cer, .crt, .p12"
        showUploadList={true}
        beforeUpload= {(file, fileList) => {
          const reader = new FileReader(); 
          console.log("file: " + file + "-----JfileList" + JSON.stringify(file)) ;
          reader.onload = (e) => {
                console.log("FILE OBJ: " + JSON.stringify(e.target.result))
                file.base64 = e.target.result;
                // form.setFieldValue({identityProviderCertificate: 'asdf'})
                // const tempCertList = [...certList.current];
                // tempCertList.push(e.target.result)
                // certList.current = tempCertList;
                // console.log("CERT LIST " + getJson(certList))
              // form.setFieldValue({identityProviderCertificateList: tempCertList})
              // setCertList(tempCertList)
              
          };

        reader.readAsDataURL(file);    
        return false;
        }}
        multiple={false}
        maxCount={1}
        onRemove={(vals) => {
          console.log("VALS" + JSON.stringify(vals))
          // console.log("TEMP LIST: length: " + certList.current.length + "====CERT" + JSON.stringify(certList, null, 2))
          console.log("CURRENT LIST : length: " + certList.current.length  + getJson(form.getFieldValue(uploadFileProps?.name)))
          if (certList.current.length > 1 && form.getFieldValue(uploadFileProps?.name)) {
            const tempCertList = [...certList.current];
            const idx = form.getFieldValue(uploadFileProps?.name).findIndex((cert)=> {
              return cert.uid === vals?.uid
            })
            console.log("IDX: " + idx)
            if (idx >= 0) {
              console.log("SPLICTING AT INDEX" + idx)
              certList.current = tempCertList.splice(idx, 1)
            };
            
          } else {
            certList.current = [];
          }
          console.log("CERT LIST  AFTER SPLICE" + JSON.stringify(certList.current))
        }}
        >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </Form.Item>
  )
}

const GetElement = (elementProps) => {
  const messageAlert = elementProps?.alertType ? <Alert message={elementProps?.element} closable={false} type={elementProps?.alertType} className='messageWarningText'/>
    : <span className="messageAlertText">{elementProps?.element}</span>
  const formLabelText = (elementProps?.label) ? 
  (<Form.Item
        name='term-type'
        label={elementProps?.label}
        key={Math.random()}
    >
        {elementProps?.element}
    </Form.Item>)
  : 
  (<Row>
    <Col span={18} offset={2} >
      {messageAlert}
    </Col>
  </Row>)

  return <div key={Math.random()}>{formLabelText}</div>;
}

const GetFormItems = (formProps) => {
  const { formItemsList, form, certList, setCertList } = formProps;
  const formItems = [];

  if (!formItemsList) return null;
  for (let x = 0; x < formItemsList.length; x ++) {
    if (formItemsList[x]?.type === "element") {
      formItems[x] = GetElement(formItemsList[x]);
    } else if (formItemsList[x]?.type === "switch") {
      formItems[x] = GetSwitch(formItemsList[x])
    } else if (formItemsList[x]?.type === "textarea") {
      formItems[x] = GetTextArea(formItemsList[x])
    } else if (formItemsList[x]?.type === "file") {
      formItems[x] = GetUploadFile(formItemsList[x], form, certList, setCertList)
    } else if (formItemsList[x]?.type === "input") {
      formItems[x] = GetInput(formItemsList[x])
    } else {
      formItems[x] =  GetInput(formItemsList[x])
    }
   
  }
  return formItems;
}

export default GetFormItems;