// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --main-background-color: #EFEFEF;
    --font-family-sans-serif: 'Lato', sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
    --valid-feedback-color: #3f9b6f;
    --invalid-feedback-color: #d9534f
}

body {
  font-family: var(--font-family-sans-serif);
  margin: 0;
  overflow: auto;
  padding: 0;
  background-color: #ffffff;
}

h2{
  color: #333333!important;
}

.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 210px;
}

.App-header {
  background-color: #222;
  /* height: 250px; */
  padding: 0px;
  color: white;
}

.App-intro {
  font-size: large;
}

.readOnlyText{
  border: 0;
  width: 100%;
  margin: 0px;
}

.errorMsg{
  color: var(--invalid-feedback-color);
}
`, "",{"version":3,"sources":["webpack://./src/assets/style/main.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,4CAA4C;IAC5C;aACS;IACT,+BAA+B;IAC/B;AACJ;;AAEA;EACE,0CAA0C;EAC1C,SAAS;EACT,cAAc;EACd,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kDAAkD;EAClD,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,WAAW;EACX,WAAW;AACb;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[":root {\n    --main-background-color: #EFEFEF;\n    --font-family-sans-serif: 'Lato', sans-serif;\n    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',\n    monospace;\n    --valid-feedback-color: #3f9b6f;\n    --invalid-feedback-color: #d9534f\n}\n\nbody {\n  font-family: var(--font-family-sans-serif);\n  margin: 0;\n  overflow: auto;\n  padding: 0;\n  background-color: #ffffff;\n}\n\nh2{\n  color: #333333!important;\n}\n\n.App {\n  text-align: center;\n}\n\n.App-logo {\n  /* animation: App-logo-spin infinite 20s linear; */\n  height: 210px;\n}\n\n.App-header {\n  background-color: #222;\n  /* height: 250px; */\n  padding: 0px;\n  color: white;\n}\n\n.App-intro {\n  font-size: large;\n}\n\n.readOnlyText{\n  border: 0;\n  width: 100%;\n  margin: 0px;\n}\n\n.errorMsg{\n  color: var(--invalid-feedback-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
