import React from "react";
import { Tooltip, Button } from "antd";
import { FaQuestionCircle } from 'react-icons/fa';
import getConfig from 'src/utils/ConfigHelper';
import { testUrl } from "src/utils/utilities";
import "src/components/SSOConfiguration/SSOConfiguration.css"

const identityProviderFormItemsConfig = (domainResponse, pageMetaData, panwInfoPanelVisible, activatedInOktaChecked, enableSSOChecked, confirmOktaSwitch, confirmEnableSSO, downLoadMetaDataBackup) => {

  const importantMessage = () => {

    const message = (domainResponse?.migratedToOkta) ? (
    <span>
      <b><u>Important:</u></b> Please review the migration instructions <a href={getConfig('DOCUMENT_LINK')} target="_blank">here</a> before you proceed.
      </span>) 
    : (<span style={{paddingBottom: 100}}>
    <b><u>Important:</u></b> Please review the setup 
    instructions <a href={getConfig('SETUP_DOCUMENT_LINK')} target="_blank">here</a> before you proceed.
    </span>)

    return {
      id: 'importantMessage',
      name: 'importantMessage',
      type: 'element',
      label: false,
      alertType: 'warning',
      element: message
    };
  }
  
  const messageMigratedToOkta = () => {
    return domainResponse?.migratedToOkta ? {
      id: 'messageMigratedToOkta',
      name: 'messageMigratedToOkta',
      type: 'element',
      label: false,
      element: <div className="ant-alert-bg-white">We highly recommend that you take a backup of your existing IDP configuration as it's required for rollback. 
      Click <a href="#" onClick={() => (downLoadMetaDataBackup())}>
      here</a> to download</div>
    } : false;
  }

  const messageNewIntegration = () => {
    return domainResponse?.migratedToOkta ? {
      id: 'messageNewIntegration',
      name: 'messageNewIntegration',
      type: 'element',
      label: false,
      element: <div className="ant-alert-bg-white">
        <span> If you have additional domains that needs to be enabled for 3rd party Idp, please open a support case.</span>
        </div>,
   } : false;
  }

  const identityProviderIdInput = () => {
    return {
      id: 'identityProviderId',
      name: 'identityProviderId',
      type: 'text',
      disabled: !domainResponse?.enableForm,
      label: (<Tooltip title='Issuer URI of the Identity Provider. This value is usually the SAML Metadata EntityID of the IdP EntityDescriptor.'>
        <span id="msgfieldPANWInfo">Identity Provider ID</span> <FaQuestionCircle className='tooltip-icon'/></Tooltip> ),
      placeholder: '',
      defaultValue: domainResponse?.identityProviderId,
      rules: [{ required: true, message: 'Please provide ID for Identity Provider' }],
    }
  }

  const identityProviderCertificateInput = () => {
    return !panwInfoPanelVisible ?
    {
      id: 'identityProviderCertificate',
      type: 'file', //orig
      name: 'identityProviderCertificate',
      disabled: !domainResponse?.enableForm,
      label: <Tooltip title='The PEM or DER encoded public key certificate of the Identity Provider used to verify SAML message and assertion signatures.'> 
         <span id="msgfieldPANWInfo">Identity Provider Certificate</span> <FaQuestionCircle className='tooltip-icon'/></Tooltip>,
      description: 'Upload File',
      rules: [{ required: true, message: 'Please provide certificate file' }],
    }
    :
    {
      id: 'identityProviderCertificateText',
      name: 'identityProviderCertificateText',
      type: 'textarea',
      disabled: !domainResponse?.enableForm,
      label: <Tooltip title='The PEM or DER encoded public key certificate of the Identity Provider used to verify SAML message and assertion signatures.'> 
         <span id="msgfieldPANWInfo">Identity Provider Certificate</span> <FaQuestionCircle className='tooltip-icon'/></Tooltip>,
  
      placeholder: '',
      defaultValue: domainResponse?.identityProviderCertificate,
      description: 'Upload File',
      rules: [{ required: true, message: 'Please provide certificate file' }],
    }
  }

  const identityProviderSSOServiceURL = () => {
    return {
      id: 'identityProviderSSOServiceURL',
      name: 'identityProviderSSOServiceURL',
      type: 'text',
      disabled: !domainResponse?.enableForm,
      // readOnly: true, /// <---- check on this?  This should
      label: <Tooltip title='The binding-specific IdP Authentication Request Protocol endpoint that receives SAML AuthnRequest messages from Palo Alto Networks.'>
        <span className='requiredMark'>*</span>
        <span id="msgfieldPANWInfo">Identity Provider SSO Service URL</span> <FaQuestionCircle className='tooltip-icon'/></Tooltip>,
  
      placeholder: '',
      defaultValue: domainResponse?.identityProviderSSOServiceURL,
      rules: [ ({ getFieldValue }) => ({
        validator(_, value) {
          if (testUrl(getFieldValue('identityProviderSSOServiceURL'))) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Please provide valid Service URL"));
        },
      }),],

    }
  }

  const identityProviderSSORedirectURL = () => {
      return {
        id: 'identityProviderSSORedirectURL',
        name: 'identityProviderSSORedirectURL',
        type: 'text',
        disabled: !domainResponse?.enableForm,
        label:<Tooltip title='The value of the destination in the SAML Authentication Request. Leave it empty to exclude it. It will be defaulted to Identity Provider SSO URL in the SAML Request.'>
           <span id="msgfieldPANWInfo">Identity Provider Destination URL</span> <FaQuestionCircle className='tooltip-icon'/>
        </Tooltip> ,
    
        placeholder: '',
        defaultValue: domainResponse?.identityProviderSSORedirectURL,
        hasFeedback: true,
        rules: [{required: false }],
      }
  }

  const activateOktaMessage = () => {
    return domainResponse?.migratedToOkta ? {
      id: 'activateOktaMessage',
      name: 'activateOktaMessage',
      type: 'element',
      alertType: 'warning',
      label: false,
      element: <>Please make sure you update your IDP configuration with the new details displayed below and test the SSO setup. 
      You can test it by visiting the Identity Provider SSO Service URL on an incognito window.</>
    }:false
  }

  const activateInOkta = () => {

    return domainResponse?.migratedToOkta ? { //orig
      id: 'activateInOkta',
      name: 'activateInOkta',
      type: 'switch',
      label: <Tooltip title='This will activate/deactivate SSO configuration in OKTA.'>
            <span id="msgfieldPANWInfo">Activate In OKTA</span> <FaQuestionCircle className='tooltip-icon'/></Tooltip>,
      placeholder: '',
      defaultValue: activatedInOktaChecked,
      rules: [{ required: false }],
      onChange: (val) => confirmOktaSwitch(val)
   }:false
  }
  

  const enableSSOMessage = () => {
    return  domainResponse?.displayEnableSSO ? { //orig
      id: 'enableSSOMessage',
      name: 'enableSSOMessage',
      type: 'element',
      alertType: 'warning',
      label: false,
      element: <>
      Please make sure you test the SSO setup before you Enable the Identity Provider. You can test it by visiting the Identity Provider SSO Service URL on an incognito window.
      </>
    }:false
  }

  const enableSSO = () => {
    return domainResponse?.displayEnableSSO ? { // orig
      id: 'enableSSO',
      name: 'enableSSO',
      type: 'switch',
      label: <Tooltip title='This will Enable/disable the Identity Provider. If Enabled, it will redirect 
             all the users except domain admins to the configured identity provider for authentication.'>
            <span id="msgfieldPANWInfo">Enable Identity Provider</span> <FaQuestionCircle className='tooltip-icon'/></Tooltip>,
      placeholder: '',
      defaultValue: enableSSOChecked,
      rules: [{ required: false }],
      onChange: (val) => confirmEnableSSO(val),
    }:false
  }

  const msgfieldRequired = () => {
    return {
      id: 'msgfieldRequired',
      name: 'msgfieldRequired',
      type: 'element',
      label: false,
      element: <span className="button-right">
        <a className="ant-btn amber-btn simple" href={getConfig('CSP_URL') + getConfig('ADD_USERS_PATH') + pageMetaData?.supportAccountId + getConfig('CONFIG')} target="_blank"><b>Add Users</b></a>
        <span className="spacer"></span>
        <a  className="ant-btn amber-btn simple" href={getConfig('APP_PORTAL_URL')+getConfig('ROLES_PATH')}  target="_blank"><b>Assign App Roles</b></a>
        <span className="spacer"></span>
        {domainResponse?.enableForm ? (<Button type="primary" htmlType="submit" shape="round" size='large' disabled={!domainResponse?.enableForm}>Save</Button>) : null}
        </span>,
  }


  }

  return [
    importantMessage(),
    messageMigratedToOkta(), 
    messageNewIntegration(), 
    identityProviderIdInput(), 
    identityProviderCertificateInput(), 
    identityProviderSSOServiceURL(),
    identityProviderSSORedirectURL(), 
    activateOktaMessage(), 
    activateInOkta(), 
    enableSSOMessage(),  
    enableSSO(), 
    msgfieldRequired(),
  ].filter(Boolean)
}

export default identityProviderFormItemsConfig;