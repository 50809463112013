import React from 'react'
import { Link } from 'react-router-dom';
import PageNotFound from '../../assets/images/NotFound.png';

const NotFound = () => (
<div>
  <img
    src={PageNotFound}
    alt="Page not found"
    style={{width: 500, height: 400, display: 'block', margin: 'auto', position: 'relative' }}
  />
  <center>
    <h4><Link to="/">Return to Home Page</Link></h4>
  </center>
</div>
);
export default NotFound
