import React, { useEffect, useState, useRef} from 'react';
import store from 'store';
import { Form  } from 'antd';
import HttpClient from 'src/utils/HttpClient';
import getConfig from 'src/utils/ConfigHelper';
import domainResponseDto from 'src/models/DomainResponseDto';
import idpMetadataResponseDto from 'src/models/IdpMetadataResponseDto';
import GetFormItems from 'src/utils/GetFormItems';
import pingBackupSPInformationQa from 'src/assets/metadata/PingBackupSPInformation-Qa.txt'
import pingBackupSPInformationProd from 'src/assets/metadata/PingBackupSPInformation-Prod.txt'
import identityProviderFormItemsConfig from 'src/utils/identityProviderFormItemsConfig';
import panwProviderFormItemsConfig from 'src/utils/panwProviderFormItemsConfig';
import ModalDialog from 'src/components/ModalDialog/ModalDialog'
import 'src/components/SSOConfiguration/SSOConfiguration.css';
// import { getJson } from 'src/utils/utilities'; // for testing 

const SSO_CONFIGURATION_ENABLE_API = getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_ENABLE_API');
const SSO_CONFIGURATION_POST_API = getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_POST_API');
const SSO_CONFIGURATION_DOMAIN_METADATA_API = getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_DOMAIN_METADATA_API');
const SSO_CONFIGURATION_DOMAIN_ACTIVATE_API= getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_DOMAIN_ACTIVATE_API');


const IdentityProviderForm = (props) => {
  const { domainResponse, setDomainResponse, panwInfoPanelVisible, setPanwInfoPanelVisible, enableSSOChecked, setEnableSSOChecked,
    activatedInOktaChecked, setActivatedInOktaChecked, pageMetaData, 
    setSubmissionStatus,
    setAfterSubmitMessage,
    setSpinnerVisible,
    setShowMessage,
  } = props;
  var domainName = store.get('domainName');
  const [form] = Form.useForm();
  const [panwForm] = Form.useForm();
  const ssoSwtichRef = useRef(null);
  const showMessageRef = useRef(false);
  const certList = useRef([])
  const [callOktaApi, setCallOktaApi] = useState(null); // value shouldn't matter, only used to trigger.
  const [callSsoApi, setCallSsoApi] = useState(null);  // value shouldn't matter, only used to trigger.
  const [callSaveApi, setCallSaveApi] = useState(null);  // value shouldn't matter, only used to trigger.
  const [copyState, setCopyState] = useState(null); // value shouldn't matter, only used to trigger.

  useEffect(()=> {
    if (callOktaApi) {
        // var button = document.getElementById(element);   
        
        const options = { authToken: `abcd` };
        var afterSubmitMessage = "Successfully ";
        var url = SSO_CONFIGURATION_DOMAIN_ACTIVATE_API.replace('{domain}',domainName);
        const values = {
            identityProviderDomain: domainName,
            activated: !activatedInOktaChecked,
        };
        setSpinnerVisible(true);

        //Submitting SSOR Configuration
        HttpClient.post(url, values, options).then((response) => {
            var status = values.activated ? 'activated' : 'deactivated';
            if (response?.data?.data !== undefined) {  
                var responseDto = domainResponseDto(response?.data?.data);
                
                setDomainResponse(responseDto);
                setPanwInfoPanelVisible(responseDto?.entityId != null);
                setEnableSSOChecked(responseDto?.isActive);
                setActivatedInOktaChecked(responseDto?.activatedInOkta || false);
                setSubmissionStatus('success');
                setAfterSubmitMessage(afterSubmitMessage + status);
                setSpinnerVisible(false);
                setCallOktaApi(false);
            }
            else {
              setSubmissionStatus('error');
              setAfterSubmitMessage(getConfig('SESSION_EXPIRED_MESSAGE'))
              setSpinnerVisible(false);
              setCallOktaApi(false);
            }
        }).catch((error) => {
            console.log("ERROR WHEN UPDATING OKTA : " + error.message)
            setSubmissionStatus('error');
            setAfterSubmitMessage(getConfig('ERROR_MESSAGE'))
            setSpinnerVisible(false);
            setCallOktaApi(false);
            setShowMessage(true);
            setCallOktaApi(false);
        });
    }
// eslint-disable-next-line 
  }, [callOktaApi])


  useEffect(()=> {
    if (callSsoApi) {
      console.log("Calling Enable callSsoApi: " + callSsoApi)
      const options = {authToken: `abcd`};
      var afterSubmitMessage = "Status successfully ";
      
      const values = form.getFieldsValue();
      values.enabled = ssoSwtichRef.current || false;
      values.identityProviderDomain = store.get('domainName');
      
      setSpinnerVisible(true);
      //Submitting SSOR Configuration
      HttpClient.post(SSO_CONFIGURATION_ENABLE_API, values, options).then((response) => {
        // if(response.data.data !== undefined  &&  response.data.data == true){ //orig
        if(response?.data){
            var status = values.enabled ? 'enabled' : 'disabled';
            
            setSpinnerVisible(false);
            // setEnableSSOChecked(response?.data?.data?.isActive || true) //orig?
            setEnableSSOChecked(values.enabled)
            setSubmissionStatus('success');
            setAfterSubmitMessage(afterSubmitMessage + status);
            setCallSsoApi(false)
            setShowMessage(true);
        }
        else{
          console.log("NO DATA FROM API CALL: " + JSON.stringify(response))
          setSubmissionStatus('error');
          setAfterSubmitMessage(getConfig('SESSION_EXPIRED_MESSAGE'));
          setSpinnerVisible(false);
          setEnableSSOChecked(enableSSOChecked)
          setCallSsoApi(false)
          setShowMessage(true);
        }
        
      }).catch((error) => {
          console.log("ERROR CALLING SSO CONFIGURATION: " + JSON.stringify(error?.message))
          var errormessage = getConfig('ERROR_MESSAGE');
          if(error.response.status === "400"){
            errormessage = getConfig('BAD_REQUEST_ERROR_MESSAGE');
          }
          
          setAfterSubmitMessage(errormessage);
          setEnableSSOChecked(enableSSOChecked)
          setSpinnerVisible(false);
          setSubmissionStatus('error');
          setCallSsoApi(false)
          setShowMessage(true);
      });
    } 
    // eslint-disable-next-line 
  }, [form, callSsoApi])


  useEffect(() => {
    if (callSaveApi) {
      setSpinnerVisible(true);
      console.log("Saving Configuration: " + callSaveApi)
        var responseMessageSuccess = "Configuration saved";
        const options = {authToken: `abcd`}
        const values = form.getFieldsValue();
        if (Array.isArray(values?.identityProviderCertificate) && values?.identityProviderCertificate[0]?.originFileObj?.base64 ) {
          values.identityProviderCertificate = values.identityProviderCertificate[0]?.originFileObj?.base64;
        }

        // Submitting SSOR Configuration
        // console.log("values: " + JSON.stringify(values))
        HttpClient.post(SSO_CONFIGURATION_POST_API, values, options).then((response) => {
          
          if(response?.data?.data ){       
              // console.log("RESPONSE AFTER UPDATE: " + JSON.stringify(response?.data?.data))
              var responseDto = domainResponseDto(response.data.data);
              setDomainResponse(responseDto);
              setSubmissionStatus('success');
              setAfterSubmitMessage(responseMessageSuccess);
              setShowMessage(true)
              setPanwInfoPanelVisible(true);
              setEnableSSOChecked(responseDto.isActive);
              setActivatedInOktaChecked(responseDto.activatedInOkta);
              setSpinnerVisible(false);
              setCallSaveApi(false);
           }
           else{

            setSubmissionStatus('error');
            setAfterSubmitMessage(getConfig('SESSION_EXPIRED_MESSAGE'),);
            setSpinnerVisible(false);
            setShowMessage(true);
            setCallSaveApi(false);
          }
        }).catch((error) => {
            console.log("ERROR Updating SSO Configuration: " + error?.message);
            // eslint-disable-next-line 
            var responseMessageError = (error?.response?.status == "400") ? getConfig('BAD_REQUEST_ERROR_MESSAGE') : getConfig('ERROR_MESSAGE');
            setSubmissionStatus('error');
            setAfterSubmitMessage(responseMessageError);
            setSpinnerVisible(false);
            setShowMessage(true);
            setCallSaveApi(false);
        });
    } 
    // eslint-disable-next-line 
  }, [callSaveApi, form])

  const setupFormVals = (formItemsConfig) => {
    const formFieldsConfig = {}
    if (formItemsConfig.length > 0) {
      for (let i = 0; i <= formItemsConfig.length; i++){
        if (["input", "textarea", "switch", "text", "file"].includes(formItemsConfig[i]?.type) ) {
          formFieldsConfig[formItemsConfig[i]?.name] = formItemsConfig[i]?.defaultValue
        }       
      }
    }
    return formFieldsConfig
  }

  const downLoadMetaData = (domain,idpId) => {  
    setSpinnerVisible(true);
    var url=SSO_CONFIGURATION_DOMAIN_METADATA_API.replace('{domain}',domain);
    url=url.replace('{idPId}',idpId);
    
    const options = {authToken: `abcd`}
    HttpClient.get(url, options).then(response => {
      if(response?.data?.data){
          var responseDto = idpMetadataResponseDto(response?.data?.data);
          var metadataValue = responseDto?.metadata;
          downLoadFile(metadataValue,'.xml','metadata.xml');
          setSpinnerVisible(false);
      }
      else{
        console.log("downLoadMetaData no response")
        setSpinnerVisible(false);
        setSubmissionStatus('error');
        setAfterSubmitMessage(getConfig("ERROR_MESSAGE"))
        setShowMessage(true);
      }
    }).catch(error => {
      console.log("downLoadMetaData Error: " + error.message)
      showMessageRef.current = false;
      setSpinnerVisible(false);
      setSubmissionStatus('error');
      setAfterSubmitMessage(error.message)
      setShowMessage(true);
      
   });
  }

  const downLoadFile = (value,fileType,fileName) => {
    setSpinnerVisible(true)
    var blob = new Blob([value], { type: fileType });

    var a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    
    setTimeout(function() { URL.revokeObjectURL(a.href);  setSpinnerVisible(false)}, 1500); 
  }

  const downLoadMetaDataBackup = () => {
    var postData = new FormData();
		var xhr = new XMLHttpRequest();
    if(getConfig('ENV') !== 'prod'){
		   xhr.open('GET',  pingBackupSPInformationQa, true);
    }
    else{
      xhr.open('GET',  pingBackupSPInformationProd, true);
    }

		xhr.responseType = 'blob';
		xhr.onload = function (e) {
			var blob = xhr.response;
      downLoadFile(blob,'.txt','PANW-PingBackupSPInformation.txt');
		}
		xhr.send(postData);

  }

  const copyToClipboard = (element) => {
    var r = document.createRange();
    r.selectNode(document.getElementById(element));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    setCopyState(true)
  }

  const confirmActivateMessage = (checkedVal) => {
    var contentStr = 'This will deactivate the Okta configuration and switch to the old setup for ' + domainName + '.';
    var titleStr='Are you sure you want to deactivate SSO configuration for ' + domainName + '?';
    if(checkedVal){
      titleStr='Are you sure you want to migrate and activate SSO configuration for ' + domainName + '?';
      contentStr='This will migrate SSO configuration and activate it for ' + domainName + '. Please make sure you have updated the IDP configuration with the new PANW SSO data displayed below.';
    }
    return {
      title: titleStr,
      content: contentStr
    }
  }

  const confirmOktaSwitch = (val) => {
    return {
      modalDialog: ModalDialog({ 
        dialogObj: confirmActivateMessage(val), 
        onOkFunc: async () => {
          await setCallOktaApi(true);
      }}),
    }
  }

  const confirmEnableSSOMessage = (val) => {
    var status = val ? 'enable' : 'disable';
    const titleText = 'Are you sure you want to '+ status + ' SSO for the domain ' + domainName + '?'
    

    var dialogText='All configurations will be saved and user authentication will happen via your Identity Provider’s SSO connection. Please confirm you have verified the SSO setup before enabling.';
    if(status === 'disable'){
      dialogText = 'All configurations will be saved and user authentication will happen via Palo Alto Networks SSO. Are you sure you want to disable it?';
    }

    return {
      title: titleText,
      content: dialogText,
      okText: 'Yes',
      cancelText: 'No'
      };
  }

  const confirmEnableSSO= (val) => {
    return {
    modalDialog: ModalDialog({ 
      dialogObj: confirmEnableSSOMessage(val), 
      onOkFunc: () => {
        console.log('save');
        setCallSsoApi(true);
        ssoSwtichRef.current = val
    }}),
  }
  }

  const confirmSaveMessage = (values) => {
    const titleStr = 'Are you sure you want to save the changes?';
    const contentStr = 'If SSO is already active, the changes will take effect immediately.';

    return {
      title: titleStr,
      content: contentStr,
      okText: 'Save'
    }
  }


  const GetPanwInfoForm = (panProps) => {
    const {domainResponse, panwInfoPanelVisible} = panProps;
    return panwInfoPanelVisible && (
      <div>
        <br/>
        <h2>Palo Alto Networks Service Provider Information</h2>
        <Form
          name={'panwFormConfig'}
          form={panwForm}
          layout="horizontal"
          labelCol={{ span: 8 }}  
          wrapperCol={{ span: 16 }}
          fields={[
            {
              name: ["msgfieldPANWInfo"],
              value: "Your identity provider may require you to enter information about Palo Alto Networks.",
            },
          ]}
        >
          <GetFormItems formItemsList={panwProviderFormItemsConfig(domainResponse, copyToClipboard, downLoadFile, downLoadMetaData).items}/>
        </Form>
      </div>)
  }


  const onFinishConfirm = (vals) => {
    ModalDialog({
    dialogObj: confirmSaveMessage(vals),
    onOkFunc:  async () => {
      setCallSaveApi(true);
    },
  })}

  const identityProviderFormItems = identityProviderFormItemsConfig(domainResponse, pageMetaData, panwInfoPanelVisible, activatedInOktaChecked, enableSSOChecked, confirmOktaSwitch, confirmEnableSSO, downLoadMetaDataBackup);

  
  useEffect(()=> {
    if (callSaveApi === null && callSsoApi === null && callOktaApi===null && copyState == null){
      // only load form once during rerenders
      form.setFieldsValue(setupFormVals(identityProviderFormItems))
    }
      
  }, [form, identityProviderFormItems, callSaveApi, callSsoApi, callOktaApi, copyState ])

  return (
    <div>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinishConfirm}
        onChange={(e) => {
          form.setFieldValue([e.target.id], e.target.value);
        }}
        >
        <GetFormItems formItemsList={identityProviderFormItems} form={form} certList={certList} />
      </Form>
      <GetPanwInfoForm domainResponse={domainResponse} panwInfoPanelVisible={panwInfoPanelVisible}/>
    </div>
  )
}

export default IdentityProviderForm; 
